import React, { useEffect, useMemo, useState } from 'react';

// utility
import { api, endpoints } from '../../api';

// components
import Collapse from '@mui/material/Collapse';
import { Box, Card, CardContent, Stack, Typography, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { host } from '../../host';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { VulnerabilityChipCheck } from 'utilities/vulnerabilityAndSignatureCheck';
import { CVE_FIXEDIN_PAGE_SIZE } from 'utilities/paginationConstants';
import VulnerabilityPackageSection from './VulnerabilityPackageSection';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    background: '#FFFFFF',
    boxShadow: '0rem 0.3125rem 0.625rem rgba(131, 131, 131, 0.08)',
    border: '1px solid #E0E5EB',
    borderRadius: '0.75rem',
    flex: 'none',
    alignSelf: 'stretch',
    width: '100%',
    marginTop: '2rem',
    marginBottom: '2rem'
  },
  cardCollapsed: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    background: '#FFFFFF',
    boxShadow: '0rem 0.3125rem 0.625rem rgba(131, 131, 131, 0.08)',
    border: '1px solid #E0E5EB',
    borderRadius: '0.75rem',
    flex: 'none',
    alignSelf: 'stretch',
    width: '100%'
  },
  content: {
    textAlign: 'left',
    color: '#606060',
    padding: '2% 3% 2% 3%',
    width: '100%'
  },
  contentCollapsed: {
    textAlign: 'left',
    color: '#606060',
    padding: '1% 3% 1% 3%',
    width: '100%',
    '&:last-child': {
      paddingBottom: '1%'
    }
  },
  cveId: {
    color: theme.palette.primary.main,
    fontSize: '1rem',
    fontWeight: 400,
    textDecoration: 'underline'
  },
  cveIdCollapsed: {
    color: theme.palette.primary.main,
    fontSize: '0.75rem',
    fontWeight: 500,
    textDecoration: 'underline',
    flexBasis: '19%'
  },
  cveSummary: {
    color: theme.palette.secondary.dark,
    fontSize: '0.75rem',
    fontWeight: '600',
    textOverflow: 'ellipsis',
    marginTop: '0.5rem'
  },
  cveSummaryCollapsed: {
    color: theme.palette.secondary.dark,
    fontSize: '0.75rem',
    fontWeight: '600',
    textOverflow: 'ellipsis',
    flexBasis: '82%'
  },
  link: {
    color: '#52637A',
    fontSize: '1rem',
    letterSpacing: '0.009375rem',
    paddingRight: '1rem',
    textDecorationLine: 'underline'
  },
  dropdown: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  dropdownText: {
    color: '#1479FF',
    fontSize: '0.75rem',
    fontWeight: '600',
    cursor: 'pointer',
    textAlign: 'center'
  },
  dropdownCVE: {
    color: '#1479FF',
    cursor: 'pointer'
  },
  vulnerabilityCardDivider: {
    margin: '1rem 0'
  },
  cveInfo: {
    marginTop: '2%'
  }
}));
function VulnerabilitiyCard(props) {
  const classes = useStyles();
  const { cve, name, platform, expand } = props;
  const [openCVE, setOpenCVE] = useState(expand);
  const [loadingFixed, setLoadingFixed] = useState(true);
  const [fixedInfo, setFixedInfo] = useState([]);
  const abortController = useMemo(() => new AbortController(), []);

  // pagination props
  const [pageNumber, setPageNumber] = useState(1);
  const [isEndOfList, setIsEndOfList] = useState(false);
  const [loadMoreInfo, setLoadMoreInfo] = useState(false);

  const getPaginatedResults = () => {
    if (!openCVE || (!loadMoreInfo && !isEmpty(fixedInfo)) || isEndOfList) {
      return;
    }
    setLoadingFixed(true);
    api
      .get(
        `${host()}${endpoints.imageListWithCVEFixed(
          cve.id,
          name,
          { pageNumber, pageSize: CVE_FIXEDIN_PAGE_SIZE },
          platform ? { Os: platform.Os, Arch: platform.Arch } : {}
        )}`,
        abortController.signal
      )
      .then((response) => {
        if (response.data && response.data.data) {
          const fixedTagsList = response.data.data.ImageListWithCVEFixed?.Results?.map((e) => e.Tag);
          setFixedInfo((previousState) => [...previousState, ...fixedTagsList]);
          setIsEndOfList(
            [...fixedInfo, ...fixedTagsList].length >= response.data.data.ImageListWithCVEFixed?.Page?.TotalCount
          );
        }
        setLoadingFixed(false);
        setLoadMoreInfo(false);
      })
      .catch((e) => {
        console.error(e);
        setIsEndOfList(true);
        setLoadingFixed(false);
        setLoadMoreInfo(false);
      });
  };

  useEffect(() => {
    getPaginatedResults();
    return () => {
      abortController.abort();
    };
  }, [openCVE, pageNumber, loadMoreInfo]);

  useEffect(() => {
    setOpenCVE(expand);
  }, [expand]);

  const loadMore = () => {
    if (loadingFixed || isEndOfList) return;
    setLoadMoreInfo(true);
    setPageNumber((pageNumber) => pageNumber + 1);
  };

  const renderFixedVer = () => {
    if (!isEmpty(fixedInfo)) {
      return fixedInfo.map((tag, index) => {
        return (
          <Link key={index} to={`/image/${encodeURIComponent(name)}/tag/${tag}`} className={classes.link}>
            {tag}
          </Link>
        );
      });
    } else {
      return 'Not fixed';
    }
  };

  const renderLoadMore = () => {
    return (
      !isEndOfList && (
        <Typography
          sx={{
            color: '#3366CC',
            cursor: 'pointer',
            fontSize: '1rem',
            letterSpacing: '0.009375rem',
            paddingRight: '1rem',
            textDecorationLine: 'underline'
          }}
          onClick={loadMore}
          component="div"
        >
          Load more
        </Typography>
      )
    );
  };

  return (
    <Card className={openCVE ? classes.card : classes.cardCollapsed} raised>
      <CardContent className={openCVE ? classes.content : classes.contentCollapsed}>
        <Stack direction="row" spacing={openCVE ? '1.25rem' : '0.5rem'}>
          {!openCVE ? (
            <KeyboardArrowRight className={classes.dropdownCVE} onClick={() => setOpenCVE(!openCVE)} />
          ) : (
            <KeyboardArrowDown className={classes.dropdownCVE} onClick={() => setOpenCVE(!openCVE)} />
          )}
          <Typography variant="body1" align="left" className={openCVE ? classes.cveId : classes.cveIdCollapsed}>
            {cve.id}
          </Typography>
          {openCVE ? (
            <VulnerabilityChipCheck vulnerabilitySeverity={cve.severity} />
          ) : (
            <Stack direction="row" spacing="0.5rem" flexBasis="90%">
              <div style={{ transform: 'scale(0.8)', flexBasis: '18%', flexShrink: '0' }}>
                <VulnerabilityChipCheck vulnerabilitySeverity={cve.severity} />
              </div>
              <Typography variant="body1" align="left" className={classes.cveSummaryCollapsed}>
                {cve.title}
              </Typography>
            </Stack>
          )}
        </Stack>
        <Collapse in={openCVE} timeout="auto" unmountOnExit>
          <Typography variant="body1" align="left" className={classes.cveSummary}>
            {cve.title}
          </Typography>
          <Divider className={classes.vulnerabilityCardDivider} />
          <Typography variant="body2" align="left" className={classes.cveInfo}>
            External reference
          </Typography>
          <Typography
            variant="body2"
            align="left"
            sx={{ color: '#0F2139', fontSize: '1rem', textDecoration: 'underline' }}
            component={Link}
            to={cve.reference}
            target="_blank"
            rel="noreferrer"
          >
            {cve.reference}
          </Typography>
          <Typography variant="body2" align="left" className={classes.cveInfo}>
            Packages
          </Typography>
          <Stack
            direction="column"
            spacing="0.3rem"
            sx={{ width: '100%', padding: '0.5rem 0' }}
            data-testid="cve-package-list"
          >
            {cve.packageList.map((pkg) => (
              <VulnerabilityPackageSection key={`${cve.id}-${pkg.packageName}`} cve={pkg} />
            ))}
          </Stack>
          <Typography variant="body2" align="left" className={classes.cveInfo}>
            Fixed in
          </Typography>
          <Box sx={{ width: '100%', padding: '0.5rem 0' }}>
            {loadingFixed ? (
              'Loading...'
            ) : (
              <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
                {renderFixedVer()}
                {renderLoadMore()}
              </Stack>
            )}
          </Box>
          <Typography variant="body2" align="left" className={classes.cveInfo}>
            Description
          </Typography>
          <Box sx={{ padding: '0.5rem 0' }}>
            <Typography variant="body2" align="left" sx={{ color: '#0F2139', fontSize: '1rem' }}>
              {cve.description}
            </Typography>
          </Box>
        </Collapse>
      </CardContent>
    </Card>
  );
}

export default VulnerabilitiyCard;
