import React from 'react';
import { Chip, Tooltip, Badge } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as failedScanBug } from '../assets/failedScan.svg';
import { createSvgIcon } from '@mui/material/utils';
import SignatureTooltip from 'components/Shared/SignatureTooltip';
import filterConstants from 'utilities/filterConstants';

const FilledBugIcon = createSvgIcon(
  <path d="M17.0293 5.13093V6.1543H18.3828L21.2414 3.24068L22.2621 4.27812L19.5552 7.03876L19.5879 7.12668C20.1841 8.73695 20.4862 10.4449 20.4793 12.1662C20.4793 12.5064 20.4678 12.8466 20.4448 13.186L20.4397 13.2634H24V14.7334H20.2569L20.2466 14.7932C19.9431 16.4882 19.3517 18.0338 18.5466 19.335L18.4862 19.4335L21.9276 22.9608L20.9052 24L17.6121 20.6239L17.5138 20.7365C16.0259 22.4333 14.0983 23.4514 11.9983 23.4514C9.86724 23.4514 7.91207 22.4016 6.41552 20.6573L6.31552 20.5413L3.08966 23.833L2.06897 22.792L5.45345 19.3403L5.39483 19.2436C4.61897 17.9618 4.04655 16.4478 3.75 14.7932L3.73966 14.7334H0V13.2634H3.55862L3.55345 13.1843C3.53103 12.8502 3.51897 12.509 3.51897 12.1644C3.51202 10.4654 3.80581 8.77905 4.38621 7.18646L4.41897 7.1003L1.64138 4.2535L2.66379 3.21606L5.53103 6.1543H6.96724V5.13093C6.96724 3.77012 7.49729 2.46505 8.4408 1.50281C9.3843 0.540578 10.664 0 11.9983 0C13.3326 0 14.6123 0.540578 15.5558 1.50281C16.4993 2.46505 17.0293 3.77012 17.0293 5.13093Z" />,
  'FilledBug'
);
const OutlinedBugIcon = createSvgIcon(
  <path d="M15.3155 5.75V4.877C15.3155 3.71615 14.8544 2.60284 14.0335 1.78199C13.2127 0.961147 12.0994 0.5 10.9385 0.5C9.77765 0.5 8.66434 0.961147 7.84349 1.78199C7.02265 2.60284 6.5615 3.71615 6.5615 4.877V5.75H5.312L2.8175 3.2435L1.928 4.1285L4.3445 6.557L4.316 6.6305C3.81106 7.98908 3.55546 9.42763 3.5615 10.877C3.5615 11.171 3.572 11.462 3.5915 11.747L3.596 11.8145H0.5V13.0685H3.7535L3.7625 13.1195C4.0205 14.531 4.5185 15.8225 5.1935 16.916L5.2445 16.9985L2.3 19.943L3.188 20.831L5.9945 18.023L6.0815 18.122C7.3835 19.61 9.0845 20.5055 10.9385 20.5055C12.7655 20.5055 14.4425 19.637 15.737 18.1895L15.8225 18.0935L18.6875 20.9735L19.577 20.087L16.583 17.078L16.6355 16.994C17.336 15.884 17.8505 14.5655 18.1145 13.1195L18.1235 13.0685H21.38V11.8145H18.2825L18.287 11.7485C18.307 11.4589 18.317 11.1688 18.317 10.8785C18.323 9.41018 18.0602 7.95316 17.5415 6.5795L17.513 6.5045L19.868 4.1495L18.98 3.2645L16.493 5.75H15.3155ZM7.8155 5.75V4.877C7.8155 4.04873 8.14453 3.25438 8.73021 2.66871C9.31588 2.08303 10.1102 1.754 10.9385 1.754C11.7668 1.754 12.5611 2.08303 13.1468 2.66871C13.7325 3.25438 14.0615 4.04873 14.0615 4.877V5.75H7.817H7.8155ZM16.361 7.0055L16.391 7.085C16.8155 8.2145 17.0615 9.5 17.0615 10.877C17.0615 13.292 16.307 15.428 15.1505 16.9325C13.9955 18.434 12.494 19.25 10.9385 19.25C9.3845 19.25 7.883 18.434 6.728 16.9325C5.57 15.428 4.8155 13.292 4.8155 10.877C4.8155 9.5 5.0615 8.2145 5.4875 7.085L5.5175 7.0055H16.361Z" />,
  'OutlinedBug'
);
const UnverifiedShieldIcon = createSvgIcon(
  <path d="M9,0,0,4v6c0,5.55,3.84,10.74,9,12,5.16-1.26,9-6.45,9-12V4Zm7,10a10.47,10.47,0,0,1-7,9.93A10.47,10.47,0,0,1,2,10V5.3L9,2.19,16,5.3ZM7,7.74l1.22,1.4c.32.36.58.7.86,1.06H9.1c.28-.39.56-.72.84-1.07l1.2-1.39h1.68L9.91,10.89l3,3.37H11.14L9.89,12.79c-.33-.38-.62-.74-.92-1.13h0c-.28.39-.58.74-.9,1.13L6.8,14.26H5.09l3-3.33L5.23,7.74Z" />,
  'UnverifiedShield'
);
const CVerifiedShieldIcon = createSvgIcon(
  <path d="M11.8,13.64a1.85,1.85,0,0,1,0,.25.9.9,0,0,1,0,.18.33.33,0,0,1,0,.12.47.47,0,0,1-.09.12,1.25,1.25,0,0,1-.25.18c-.13.07-.28.13-.45.2a4.13,4.13,0,0,1-.61.16,4.35,4.35,0,0,1-.74.06,3.93,3.93,0,0,1-1.41-.24A2.91,2.91,0,0,1,7.1,14a3.32,3.32,0,0,1-.67-1.2A5.2,5.2,0,0,1,6.2,11.1a5.37,5.37,0,0,1,.25-1.72,3.85,3.85,0,0,1,.72-1.26,3,3,0,0,1,1.12-.77,3.63,3.63,0,0,1,1.42-.26,3,3,0,0,1,.61,0,2.66,2.66,0,0,1,.54.14,2.34,2.34,0,0,1,.45.19,1.84,1.84,0,0,1,.28.19l.11.13s0,.09.05.14,0,.12,0,.19a2.35,2.35,0,0,1,0,.28,2.63,2.63,0,0,1,0,.3.88.88,0,0,1,0,.2.52.52,0,0,1-.07.11.17.17,0,0,1-.1,0,.38.38,0,0,1-.22-.1c-.09-.07-.21-.14-.35-.23a3.08,3.08,0,0,0-.51-.23,2.41,2.41,0,0,0-.7-.1A1.67,1.67,0,0,0,9,8.57a1.58,1.58,0,0,0-.6.52A2.54,2.54,0,0,0,8,9.92,4.15,4.15,0,0,0,7.86,11,4.31,4.31,0,0,0,8,12.17a2.19,2.19,0,0,0,.39.81,1.55,1.55,0,0,0,.61.47,2,2,0,0,0,.82.16,2.17,2.17,0,0,0,.71-.1A2.45,2.45,0,0,0,11,13.3l.35-.21a.38.38,0,0,1,.21-.1.17.17,0,0,1,.1,0,.17.17,0,0,1,.06.09c0,.05,0,.11,0,.2A3,3,0,0,1,11.8,13.64ZM9,0,0,4v6c0,5.55,3.84,10.74,9,12,5.16-1.26,9-6.45,9-12V4Zm7,10a10.47,10.47,0,0,1-7,9.93A10.47,10.47,0,0,1,2,10V5.3L9,2.19,16,5.3Z" />,
  'VerifiedShield'
);
const NVerifiedShieldIcon = createSvgIcon(
  <path d="M12.13,14.25a.6.6,0,0,1-.05.24.45.45,0,0,1-.13.17.39.39,0,0,1-.19.1.52.52,0,0,1-.21,0h-.66a1.79,1.79,0,0,1-.36,0,.72.72,0,0,1-.27-.15,1.06,1.06,0,0,1-.24-.3c-.08-.12-.17-.28-.27-.47L7.87,10.29c-.11-.21-.22-.44-.34-.68s-.21-.48-.3-.71h0l0,.84c0,.28,0,.56,0,.86v4a.17.17,0,0,1,0,.1.19.19,0,0,1-.11.08.81.81,0,0,1-.21.05l-.35,0-.34,0A.81.81,0,0,1,6,14.75a.19.19,0,0,1-.11-.08.17.17,0,0,1,0-.1V7.75A.51.51,0,0,1,6,7.34a.56.56,0,0,1,.39-.14h.83a1.82,1.82,0,0,1,.37,0,.84.84,0,0,1,.27.13,1.06,1.06,0,0,1,.23.24A3.9,3.9,0,0,1,8.35,8l1.47,2.78.26.49.24.49.23.47c.07.16.15.32.22.47h0c0-.27,0-.56,0-.85s0-.58,0-.85V7.43a.17.17,0,0,1,0-.1.29.29,0,0,1,.12-.09.9.9,0,0,1,.22,0h.68a.72.72,0,0,1,.2,0s.09,0,.11.09a.17.17,0,0,1,0,.1ZM9,0,0,4v6c0,5.55,3.84,10.74,9,12,5.16-1.26,9-6.45,9-12V4Zm7,10a10.47,10.47,0,0,1-7,9.93A10.47,10.47,0,0,1,2,10V5.3L9,2.19,16,5.3Z" />,
  'VerifiedShield'
);

const NoneVulnerabilityIcon = ({ vulnerabilityStringTitle }) => {
  return (
    <Tooltip title={`${vulnerabilityStringTitle}`} placement="top">
      <OutlinedBugIcon
        sx={{
          color: '#43A047!important',
          padding: '0.2rem',
          background: '#E8F5E9',
          borderRadius: '1rem',
          height: '1.5rem',
          width: '1.6rem'
        }}
        data-testid="none-vulnerability-icon"
      />
    </Tooltip>
  );
};
const UnknownVulnerabilityIcon = ({ vulnerabilityStringTitle }) => {
  return (
    <Tooltip title={`${vulnerabilityStringTitle}`} placement="top">
      <OutlinedBugIcon
        sx={{
          color: '#52637A',
          padding: '0.2rem',
          background: '#ECEFF1',
          borderRadius: '1rem',
          height: '1.5rem',
          width: '1.6rem'
        }}
        data-testid="unknown-vulnerability-icon"
      />
    </Tooltip>
  );
};
const FailedScanIcon = () => {
  return (
    <Tooltip title="Failed to scan" placement="top">
      <SvgIcon
        component={failedScanBug}
        sx={{
          color: '#F6F7F9',
          padding: '0.2rem',
          background: '#848484',
          borderRadius: '1rem',
          height: '1.5rem',
          width: '1.6rem'
        }}
        data-testid="failed-vulnerability-icon"
      />
    </Tooltip>
  );
};
const LowVulnerabilityIcon = ({ vulnerabilityStringTitle }) => {
  return (
    <Tooltip title={`${vulnerabilityStringTitle}`} placement="top">
      <OutlinedBugIcon
        sx={{
          color: '#FB8C00',
          padding: '0.2rem',
          background: '#FFF3E0',
          borderRadius: '1rem',
          height: '1.5rem',
          width: '1.6rem'
        }}
        data-testid="low-vulnerability-icon"
      />
    </Tooltip>
  );
};
const MediumVulnerabilityIcon = ({ vulnerabilityStringTitle }) => {
  return (
    <Tooltip title={`${vulnerabilityStringTitle}`} placement="top">
      <FilledBugIcon
        sx={{
          color: '#FB8C00',
          padding: '0.2rem',
          background: '#FFF3E0',
          borderRadius: '1rem',
          height: '1.5rem',
          width: '1.6rem'
        }}
        data-testid="medium-vulnerability-icon"
      />
    </Tooltip>
  );
};
const HighVulnerabilityIcon = ({ vulnerabilityStringTitle }) => {
  return (
    <Tooltip title={`${vulnerabilityStringTitle}`} placement="top">
      <OutlinedBugIcon
        sx={{
          color: '#E53935',
          padding: '0.2rem',
          background: '#FEEBEE',
          borderRadius: '1rem',
          height: '1.5rem',
          width: '1.6rem'
        }}
        data-testid="high-vulnerability-icon"
      />
    </Tooltip>
  );
};
const CriticalVulnerabilityIcon = ({ vulnerabilityStringTitle }) => {
  return (
    <Tooltip title={`${vulnerabilityStringTitle}`} placement="top">
      <FilledBugIcon
        sx={{
          color: '#E53935',
          padding: '0.2rem',
          background: '#FEEBEE',
          borderRadius: '1rem',
          height: '1.5rem',
          width: '1.6rem'
        }}
        data-testid="critical-vulnerability-icon"
      />
    </Tooltip>
  );
};
const NoneVulnerabilityChip = () => {
  return (
    <Chip
      label="None"
      sx={{ backgroundColor: '#E8F5E9', color: '#388E3C', fontSize: '0.8125rem' }}
      variant="filled"
      icon={<OutlinedBugIcon sx={{ color: '#388E3C!important' }} />}
      data-testid="none-vulnerability-chip"
    />
  );
};
const UnknownVulnerabilityChip = () => {
  return (
    <Chip
      label="Unknown"
      sx={{ backgroundColor: '#ECEFF1', color: '#52637A', fontSize: '0.8125rem' }}
      variant="filled"
      icon={<OutlinedBugIcon sx={{ color: '#52637A!important' }} />}
      data-testid="unknown-vulnerability-chip"
    />
  );
};
const FailedScanChip = () => {
  return (
    <Chip
      label="Failed to scan"
      sx={{ backgroundColor: '#848484', color: '#F6F7F9', fontSize: '0.8125rem' }}
      variant="filled"
      icon={<SvgIcon component={failedScanBug} sx={{ color: '#F6F7F9!important' }} />}
      data-testid="failed-vulnerability-chip"
    />
  );
};
const LowVulnerabilityChip = () => {
  return (
    <Chip
      label="Low"
      sx={{ backgroundColor: '#FFF3E0', color: '#FB8C00', fontSize: '0.8125rem' }}
      variant="filled"
      icon={<OutlinedBugIcon sx={{ color: '#FB8C00!important' }} />}
      data-testid="low-vulnerability-chip"
    />
  );
};
const MediumVulnerabilityChip = () => {
  return (
    <Chip
      label="Medium"
      sx={{ backgroundColor: '#FFF3E0', color: '#FB8C00', fontSize: '0.8125rem' }}
      variant="filled"
      icon={<FilledBugIcon sx={{ color: '#FB8C00!important' }} />}
      data-testid="medium-vulnerability-chip"
    />
  );
};
const HighVulnerabilityChip = () => {
  return (
    <Chip
      label="High"
      sx={{ backgroundColor: '#FEEBEE', color: '#E53935', fontSize: '0.8125rem' }}
      variant="filled"
      icon={<OutlinedBugIcon sx={{ color: '#E53935!important' }} />}
      data-testid="high-vulnerability-chip"
    />
  );
};
const CriticalVulnerabilityChip = () => {
  return (
    <Chip
      label="Critical"
      sx={{ backgroundColor: '#FEEBEE', color: '#E53935', fontSize: '0.8125rem' }}
      variant="filled"
      icon={<FilledBugIcon sx={{ color: '#E53935!important' }} />}
      data-testid="critical-vulnerability-chip"
    />
  );
};

const UnverifiedSignatureIcon = ({ signatureInfo }) => {
  return (
    <Tooltip title={<SignatureTooltip signatureInfo={signatureInfo} />} placement="top">
      <UnverifiedShieldIcon
        viewBox="0 0 18 22"
        sx={{
          color: '#E53935',
          padding: '0.2rem',
          background: '#FEEBEE',
          borderRadius: '1rem',
          height: '1.5rem',
          width: '1.6rem'
        }}
        data-testid="unverified-icon"
      />
    </Tooltip>
  );
};

const NotTrustedSignatureIcon = ({ signatureInfo }) => {
  return (
    <Tooltip title={<SignatureTooltip signatureInfo={signatureInfo} />} placement="top">
      {(signatureInfo[0]?.tool && signatureInfo[0].tool == filterConstants.signatureToolConstants.NOTATION && (
        <Badge
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          overlap="circular"
          color="warning"
          badgeContent={signatureInfo.length}
        >
          <NVerifiedShieldIcon
            viewBox="0 0 18 22"
            sx={{
              backgroundColor: '#FCE2B8!important',
              color: '#FB8C00',
              alignSelf: 'center',
              padding: '0.2rem',
              background: '#E8F5E9',
              borderRadius: '1rem',
              height: '1.5rem',
              width: '1.6rem'
            }}
            data-testid="untrusted-icon"
          />
        </Badge>
      )) ||
        (signatureInfo[0]?.tool && signatureInfo[0].tool == filterConstants.signatureToolConstants.COSIGN && (
          <Badge
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            overlap="circular"
            color="warning"
            badgeContent={signatureInfo.length}
          >
            <CVerifiedShieldIcon
              viewBox="0 0 18 22"
              sx={{
                backgroundColor: '#FCE2B8!important',
                color: '#FB8C00',
                alignSelf: 'center',
                padding: '0.2rem',
                background: '#E8F5E9',
                borderRadius: '1rem',
                height: '1.5rem',
                width: '1.6rem'
              }}
              data-testid="untrusted-icon"
            />
          </Badge>
        ))}
    </Tooltip>
  );
};

const VerifiedSignatureIcon = ({ signatureInfo }) => {
  return (
    <Tooltip title={<SignatureTooltip signatureInfo={signatureInfo} />} placement="top">
      {(signatureInfo[0]?.tool && signatureInfo[0].tool == filterConstants.signatureToolConstants.NOTATION && (
        <Badge
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          overlap="circular"
          color="success"
          badgeContent={signatureInfo.length}
        >
          <NVerifiedShieldIcon
            viewBox="0 0 18 22"
            sx={{
              color: '#43A047',
              alignSelf: 'center',
              padding: '0.2rem',
              background: '#E8F5E9',
              borderRadius: '1rem',
              height: '1.5rem',
              width: '1.6rem'
            }}
            data-testid="verified-icon"
          />
        </Badge>
      )) ||
        (signatureInfo[0]?.tool && signatureInfo[0].tool == filterConstants.signatureToolConstants.COSIGN && (
          <Badge
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            overlap="circular"
            color="success"
            badgeContent={signatureInfo.length}
          >
            <CVerifiedShieldIcon
              viewBox="0 0 18 22"
              sx={{
                color: '#43A047',
                alignSelf: 'center',
                padding: '0.2rem',
                background: '#E8F5E9',
                borderRadius: '1rem',
                height: '1.5rem',
                width: '1.6rem'
              }}
              data-testid="verified-icon"
            />
          </Badge>
        ))}
    </Tooltip>
  );
};

const UnverifiedSignatureChip = () => {
  return (
    <Chip
      label="Unverified Signature"
      sx={{ backgroundColor: '#FEEBEE', color: '#E53935', fontSize: '0.8125rem' }}
      variant="filled"
      onDelete={() => {
        return;
      }}
      deleteIcon={<UnverifiedShieldIcon sx={{ color: '#E53935!important' }} />}
    />
  );
};

export {
  NoneVulnerabilityIcon,
  UnknownVulnerabilityIcon,
  LowVulnerabilityIcon,
  MediumVulnerabilityIcon,
  HighVulnerabilityIcon,
  CriticalVulnerabilityIcon,
  NoneVulnerabilityChip,
  UnknownVulnerabilityChip,
  LowVulnerabilityChip,
  MediumVulnerabilityChip,
  HighVulnerabilityChip,
  CriticalVulnerabilityChip,
  UnverifiedSignatureIcon,
  NotTrustedSignatureIcon,
  VerifiedSignatureIcon,
  UnverifiedSignatureChip,
  FailedScanIcon,
  FailedScanChip
};
